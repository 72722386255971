import React from 'react';
import styled from '@emotion/styled';

import BurgerIconDark from 'assets/svgs/burger_menu_dark.svg';
import BurgerIconLightSand from 'assets/svgs/burger_menu_light_sand.svg';
import { BREAKPOINTS } from 'utils/constants';

interface IBurgerMenuButton {
  onClick: () => void;
  isScrollMenu: boolean;
}

const Container = styled.div({
  display: 'none',
  marginLeft: 10,
  [BREAKPOINTS[800]]: {
    display: 'inline-block',
  },
});

const BurgerButton = styled.button({
  border: 'none',
  background: 'none',
  outline: 'none',
});

const BurgerMenuButton: React.FC<IBurgerMenuButton> = ({ onClick, isScrollMenu }) => (
  <Container>
    <BurgerButton onClick={onClick}>
      {isScrollMenu ? <BurgerIconDark /> : <BurgerIconLightSand />}
    </BurgerButton>
  </Container>
);

export default BurgerMenuButton;
