const React = require('react');

const PageWrapper = require('./src/components/Layout/PageWrapper').default;
const QueryParamsHandler = require('./src/components/QueryParamsHandler').default;
const GlobalStyleWrapper = require('./src/components/Layout/GlobalStyleWrapper').default;

export const wrapRootElement = ({ element }) => <GlobalStyleWrapper>{element}</GlobalStyleWrapper>;

export const wrapPageElement = ({ element, props }) => (
  <QueryParamsHandler {...props}>
    <PageWrapper {...props}>{element}</PageWrapper>
  </QueryParamsHandler>
);
