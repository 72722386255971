import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { INTERNAL_LINKS } from 'utils/constants';

interface IBurgerMenuLink {
  to: INTERNAL_LINKS;
  text: string;
}

const StyledLink = styled(Link)((props) => ({
  color: props.theme.colors.backgroundDark,
  fontSize: 24,
  textDecoration: 'none',
  letterSpacing: 1,
}));

export const BurgerMenuLink: React.FC<IBurgerMenuLink> = ({ text, to }) => (
  <StyledLink to={to}>{text}</StyledLink>
);

export default BurgerMenuLink;
