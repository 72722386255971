import styled from '@emotion/styled';

import { BREAKPOINTS } from 'utils/constants';

export default styled.a<{ isScrollMenu: boolean }>((props) => ({
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: props.isScrollMenu ? props.theme.colors.dark : props.theme.colors.white,
  color: props.isScrollMenu ? props.theme.colors.backgroundDark : props.theme.colors.lightSand,
  opacity: props.isScrollMenu ? 0.7 : 0.8,
  padding: '8px 26px',
  textDecoration: 'none',
  fontSize: 18,
  fontWeight: 400,
  borderRadius: 8,
  whiteSpace: 'nowrap',
  ':hover': {
    opacity: 1,
  },
  [BREAKPOINTS[800]]: {
    fontSize: 16,
    padding: '6px 16px',
  },
}));
