import React from 'react';
import styled from '@emotion/styled';

import AppStoreIcon from 'assets/svgs/app_store.svg';
import GooglePlayIcon from 'assets/svgs/google_play.svg';
import { BREAKPOINTS, EXTERNAL_LINKS } from 'utils/constants';

const Container = styled.div({
  marginLeft: 40,
  [BREAKPOINTS[1350]]: {
    margin: 0,
    textAlign: 'center',
  },
});

const Links = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const Title = styled.div((props) => ({
  fontSize: 16,
  color: props.theme.colors.white,
  opacity: 0.7,
  margin: '0 20px',
  [BREAKPOINTS[1350]]: {
    margin: '0 0 20px 0',
  },
}));

const LinkContainer = styled.a({
  height: 42,
  width: 160,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 40,
  margin: '10px 20px 0 20px',
  textDecoration: 'none',
});

const AppStoreLink = styled(LinkContainer)((props) => ({
  color: props.theme.colors.dark,
  backgroundColor: props.theme.colors.white,
}));

const GooglePlayLink = styled(LinkContainer)((props) => ({
  color: props.theme.colors.white,
  backgroundColor: props.theme.colors.dark,
  border: `1px solid ${props.theme.colors.white}`,
}));

const TextContainer = styled.div({
  marginLeft: 8,
  textAlign: 'left',
});

const Text = styled.div({
  fontSize: 12,
  lineHeight: '14px',
});

const BondText = styled(Text)({
  fontWeight: 'bold',
});

const AppLinks: React.FC = () => (
  <Container>
    <Title>Download Yours mobile app</Title>
    <Links>
      <AppStoreLink href={EXTERNAL_LINKS.APP_STORE} target="_blank">
        <AppStoreIcon />
        <TextContainer>
          <Text>Available on</Text>
          <BondText>App store</BondText>
        </TextContainer>
      </AppStoreLink>
      <GooglePlayLink href={EXTERNAL_LINKS.GOOGLE_PLAY} target="_blank">
        <GooglePlayIcon />
        <TextContainer>
          <Text>Available on</Text>
          <BondText>Google play</BondText>
        </TextContainer>
      </GooglePlayLink>
    </Links>
  </Container>
);

export default AppLinks;
