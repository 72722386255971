import React from 'react';
import styled from '@emotion/styled';

import BurgerMenuLink from './BurgerMenuLink';

import CloseIcon from 'assets/svgs/mobile_menu_close_dark.svg';
import Logo from 'assets/svgs/logo_dark.svg';
import { INTERNAL_LINKS } from 'utils/constants';

interface IBurgerMenu {
  isVisible: boolean;
  onClose: () => void;
}

const MobileMenu = styled.div((props) => ({
  fontFamily: 'Taviraj',
  position: 'fixed',
  overflow: 'hidden',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: props.theme.colors.lightSand,
  zIndex: 20,
  padding: '24px 40px',
}));

const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const CloseButton = styled.button({
  background: 'none',
  border: 'none',
  outline: 'none',
});

const LinksContainer = styled.div({
  marginTop: 40,
  textAlign: 'center',
});

const BurgerMenu: React.FC<IBurgerMenu> = ({ isVisible, onClose }) =>
  isVisible ? (
    <MobileMenu>
      <Header>
        <Logo />
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </Header>
      <LinksContainer>
        <BurgerMenuLink text="About Us" to={INTERNAL_LINKS.ABOUT_US} />
      </LinksContainer>
      <LinksContainer>
        <BurgerMenuLink text="Blog" to={INTERNAL_LINKS.BLOG} />
      </LinksContainer>
      <LinksContainer>
        <BurgerMenuLink text="Contributors" to={INTERNAL_LINKS.CONTRIBUTORS} />
      </LinksContainer>
    </MobileMenu>
  ) : null;

export default BurgerMenu;
