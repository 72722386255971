import React, { PropsWithChildren } from 'react';

import { QUERY_PARAMS } from 'utils/constants';
import { setCookie, COOKIE_KEY } from 'utils/cookies';

interface IQueryParamsHandler {
  location: Location;
}

const QueryParamsHandler: React.FC<PropsWithChildren<IQueryParamsHandler>> = ({
  children,
  location,
}) => {
  const params = new URLSearchParams(location.search);

  const fbclid = params.get(QUERY_PARAMS.FBCLID);
  const gclid = params.get(QUERY_PARAMS.GCLID);
  const coupon = params.get(QUERY_PARAMS.COUPON);
  const tuneOfferId = params.get(QUERY_PARAMS.TUNE_OFFER_ID);
  const tuneTransactionId = params.get(QUERY_PARAMS.TUNE_TRANSACTION_ID);
  const utmCampaign = params.get(QUERY_PARAMS.UTM_CAMPAIGN);
  const utmMedium = params.get(QUERY_PARAMS.UTM_MEDIUM);
  const utmSource = params.get(QUERY_PARAMS.UTM_SOURCE);
  const impactClickId = params.get(QUERY_PARAMS.IMPACT_CLICK_ID);

  if (fbclid) {
    setCookie(COOKIE_KEY.FBCLID, fbclid);
  }

  if (gclid) {
    setCookie(COOKIE_KEY.GCLID, gclid);
  }

  if (coupon) {
    setCookie(COOKIE_KEY.COUPON, coupon);
  }

  if (tuneOfferId) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, tuneOfferId);
  }

  if (tuneTransactionId) {
    setCookie(COOKIE_KEY.TUNE_TRANSACTION_ID, tuneTransactionId);
  }

  if (utmCampaign) {
    setCookie(COOKIE_KEY.UTM_CAMPAIGN, utmCampaign);
  }

  if (utmMedium) {
    setCookie(COOKIE_KEY.UTM_MEDIUM, utmMedium);
  }

  if (utmSource) {
    setCookie(COOKIE_KEY.UTM_SOURCE, utmSource);
  }

  if (impactClickId) {
    setCookie(COOKIE_KEY.IMPACT_CLICK_ID, impactClickId);
  }

  return <>{children}</>;
};

export default QueryParamsHandler;
