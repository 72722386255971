exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-affiliate-tsx": () => import("./../../../src/pages/affiliate.tsx" /* webpackChunkName: "component---src-pages-affiliate-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-business-blog-tsx": () => import("./../../../src/pages/business/blog.tsx" /* webpackChunkName: "component---src-pages-business-blog-tsx" */),
  "component---src-pages-business-index-tsx": () => import("./../../../src/pages/business/index.tsx" /* webpackChunkName: "component---src-pages-business-index-tsx" */),
  "component---src-pages-contributors-tsx": () => import("./../../../src/pages/contributors.tsx" /* webpackChunkName: "component---src-pages-contributors-tsx" */),
  "component---src-pages-delete-account-tsx": () => import("./../../../src/pages/delete-account.tsx" /* webpackChunkName: "component---src-pages-delete-account-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-email-unsubscribe-tsx": () => import("./../../../src/pages/email-unsubscribe.tsx" /* webpackChunkName: "component---src-pages-email-unsubscribe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-affiliate-landing-tsx": () => import("./../../../src/templates/AffiliateLanding.tsx" /* webpackChunkName: "component---src-templates-affiliate-landing-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-business-blog-page-tsx": () => import("./../../../src/templates/BusinessBlogPage.tsx" /* webpackChunkName: "component---src-templates-business-blog-page-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../../../src/templates/LegalPage.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */)
}

