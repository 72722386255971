import React from 'react';
import styled from '@emotion/styled';

import AppLinks from './AppLinks';
import InternalLinks from './InternalLinks';

import Logo from 'assets/svgs/logo.svg';
import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

export const FOOTER_HEIGHT = {
  DESKTOP: 186,
  TABLET: 500,
};

const StyledFooter = styled.footer((props) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: props.theme.colors.darkGreen,
  padding: `0 ${WEB_SIDE_PADDING}`,
  height: FOOTER_HEIGHT.DESKTOP,
  [BREAKPOINTS[1350]]: {
    height: FOOTER_HEIGHT.TABLET,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  [BREAKPOINTS[800]]: {
    padding: `0 ${MOBILE_SIDE_PADDING}`,
  },
}));

const LinksContainer = styled.div({
  display: 'flex',
  [BREAKPOINTS[1350]]: {
    flexDirection: 'column',
  },
});

const Separator = styled.div((props) => ({
  width: 0,
  border: `1px solid ${props.theme.colors.white}`,
  opacity: 0.12,
  [BREAKPOINTS[1350]]: {
    width: '100%',
    height: 0,
    margin: '40px 0',
  },
}));

const Footer: React.FC = () => (
  <StyledFooter>
    <Logo />
    <LinksContainer>
      <InternalLinks />
      <Separator />
      <AppLinks />
    </LinksContainer>
  </StyledFooter>
);

export default Footer;
