import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import { BREAKPOINTS, INTERNAL_LINKS } from 'utils/constants';
import { COOKIE_KEY, getCookie, setCookie } from 'utils/cookies';

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.backgroundDark,
  position: 'fixed',
  right: 20,
  bottom: 20,
  zIndex: 100,
  padding: 28,
  width: 324,
  border: '1px solid #22303D',
  borderRadius: 5,
  [BREAKPOINTS[800]]: {
    right: 20,
    left: 20,
    width: 'unset',
  },
}));

const Text = styled.div((props) => ({
  color: props.theme.colors.white,
  lineHeight: '18px',
  fontSize: 14,
  letterSpacing: 1,
}));

const MainText = styled.span({
  opacity: 0.6,
});

const StyledLink = styled(Link)((props) => ({
  color: props.theme.colors.white,
}));

const Button = styled.button((props) => ({
  marginTop: 20,
  color: props.theme.colors.white,
  backgroundColor: props.theme.colors.buttonBlue,
  border: 'none',
  outline: 'none',
  borderRadius: 100,
  fontSize: 16,
  width: 106,
  height: 44,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: props.theme.colors.buttonBlueHover,
  },
}));

const GdprPopup = () => {
  const location = useLocation();
  const [isAgreed, setAgreed] = useState<boolean | null>(null);

  const setGdprAgreed = () => {
    setCookie(COOKIE_KEY.GOOGLE_TAG_MANAGER, 'true');
    setAgreed(true);
    initializeAndTrack(location);
  };

  const onClick = () => {
    setGdprAgreed();
  };

  useEffect(() => {
    const isNotInitialLoad = getCookie(COOKIE_KEY.IS_NOT_INITIAL_LOAD) === 'true';

    if (isNotInitialLoad) {
      setTimeout(() => {
        setAgreed(getCookie(COOKIE_KEY.GOOGLE_TAG_MANAGER) === 'true');
      }, 1000);
    } else {
      setCookie(COOKIE_KEY.IS_NOT_INITIAL_LOAD, 'true');
      setGdprAgreed();
    }
  }, []);

  if (isAgreed || isAgreed === null) {
    return null;
  }

  return (
    <Container>
      <Text>
        <MainText>
          This website uses cookies to allow us to better understand how the site is used. By
          clicking “Agree” you agree to our use of cookies as explained in our{' '}
        </MainText>
        <StyledLink to={INTERNAL_LINKS.PRIVACY_POLICY}>Privacy Policy.</StyledLink>
      </Text>
      <Button onClick={onClick}>Agree</Button>
    </Container>
  );
};

export default GdprPopup;
