import { Theme } from '@emotion/react';

const theme: Theme = {
  colors: {
    black: '#000000',
    dark: '#0F1E27',
    darkText: '#171F23',
    backgroundDark: '#061218',
    backgroundWhite: '#f1f1f1',
    buttonBlue: '#396CD8',
    buttonBlueHover: 'rgb(42, 81, 158)',
    lighterDark: '#0F212C',
    lightGray: 'rgba(153, 159, 164, 0.2)',
    semiLightGray: '#798191',
    darkGray: '#4D5462',
    buttonGray: '#2D3739',
    white: '#FFFFFF',
    lightSand: '#FFF9F2',
    sand: '#F4E8DA',
    darkSand: '#EFE6DC',
    semiDarkSand: '#F8ECDF',
    darkBlue: '#0F2652',
    blue: '#081720',
    dealPageBlue: '#4F71DB',
    darkGreen: '#0A1821',
    orange: '#E5830E',
    blurryBlue: '#173164',
    buttonDark: '#312F2B',
    backgroundGray: '#F1F1F1',
    backgroundSand: '#FEFAF4',
    statText: '#CEB789',
    referenceText: '#ACACAC',
    backgroundLightSand: '#F9F2E0',
    footerBackground: '#1C1C1C',
    linkText: '#3442C1',
    internalLinkText: '#0066BE',
    controlBorder: 'rgba(0, 0, 0, 0.2)',
    controlFocusBorder: 'rgba(0, 0, 0, 0.5)',
    controlBackground: '#F8F6F5',
    selectOptionHover: '#F3E6DF',
    alert: '#D70C0C',
    heroBackground: '#D6CDC8',
    heroBackgroundLight: '#D8CFCA',
  },
  typography: {
    fontWeights: {
      semiBold: 600,
    },
  },
};

export default theme;
