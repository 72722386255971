export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export const CORE_API = 'https://api.yoursapp.com';

export enum QUERY_PARAMS {
  TOKEN = 'token',
  CODE = 'code',
  WEB_PLAN_ID = 'web_plan_id',
  COUPON = 'coupon',
  LICENSE_ID = 'license_id',
  GCLID = 'gclid',
  FBCLID = 'fbclid',
  TUNE_OFFER_ID = 'tune_offer_id',
  TUNE_TRANSACTION_ID = 'tune_transaction_id',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  IMPACT_CLICK_ID = 'irclickid',
}

export enum API_URLS {
  SUBSCRIBE = '/v1/users/contact/',
}

export enum PLANS_IDS {
  MONTHLY = '8e882a83-f39d-4f2d-abcf-b072f8b8a03a',
  YEARLY = 'd6d698a4-3437-4a18-ae50-e9bf12fb6ef1',
}

export enum INTERNAL_LINKS {
  LEGAL = '/legal',
  BLOG = '/blog',
  NEWS = '/news',
  ABOUT_US = '/about-us',
  PRIVACY_POLICY = '/legal/privacy-policy',
  TERMS_AND_CONDITIONS = '/legal/terms-and-conditions',
  CONTRIBUTORS = '/contributors',
  HOME = '/',
  AFFILIATE = '/affiliate',
  DEAL = '/deal',
  EMAIL_UNSUBSCRIBE = '/email-unsubscribe',
  BUSINESS = '/business',
  BUSINESS_BLOG = '/business/blog',
  DOWNLOAD = '/download',
  DELETE_ACCOUNT = '/delete-account',
}

export enum EXTERNAL_LINKS {
  FUNNEL = 'https://join.yoursapp.com/',
  FUNNEL_REGISTER = 'https://join.yoursapp.com/register',
  WEB_APP = 'https://app.yoursapp.com/',
  APP_STORE = 'https://apps.apple.com/us/app/yours-app/id1543920949',
  GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.yoursapp',
  WEB_APP_REGISTER = 'https://app.yoursapp.com/register',
  WEB_APP_LOGIN = 'https://app.yoursapp.com/login',
  BUSINESS_WEB_APP = 'https://business.yoursapp.com/register',
  BUSINESS_WEB_APP_LOGIN = 'https://business.yoursapp.com/login',
  STUDENT_BEANS = 'https://connect.studentbeans.com/v4/hosted/yours-app/us',
  AFF_YOURS_APP = 'https://affiliates.yoursapp.com/users/signup/',
  FACEBOOK = 'https://www.facebook.com/yoursappofficial/',
  INSTAGRAM = 'https://www.instagram.com/yoursappofficial/',
  LINKED_IN = 'https://www.linkedin.com/company/yours-app-official/',
  TWITTER = 'https://twitter.com/yoursapp_',
  BOOK_DEMO = 'https://calendly.com/yoursapp/demo',
}

export const BREAKPOINTS = {
  1320: '@media (max-width: 1320px)',
  1350: '@media (max-width: 1350px)',
  1200: '@media (max-width: 1200px)',
  1150: '@media (max-width: 1150px)',
  1100: '@media (max-width: 1100px)',
  955: '@media (max-width: 955px)',
  870: '@media (max-width: 870px)',
  800: '@media (max-width: 800px)',
  440: '@media (max-width: 440px)',
} as const;

export type TBreakpoint = typeof BREAKPOINTS[keyof typeof BREAKPOINTS];

export const WEB_SIDE_PADDING = '120px';
export const MOBILE_SIDE_PADDING = '20px';

export const YOURS_APP_DOMAIN = '.yoursapp.com';
