import React from 'react';
import styled from '@emotion/styled';

import LightHeader from 'components/LightHeader';
import GdprPopup from 'components/GdprPopup';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import { BREAKPOINTS, INTERNAL_LINKS } from 'utils/constants';

interface IPageWrapper {
  children: React.ReactNode;
  location: URL;
}

const Container = styled.div({
  width: '100%',
  minHeight: '100vh',
  position: 'relative',
});

const PagePaddingWrapper = styled.div({
  paddingBottom: FOOTER_HEIGHT.DESKTOP,
  [BREAKPOINTS[1350]]: {
    paddingBottom: FOOTER_HEIGHT.TABLET,
  },
});

const PageWrapper: React.FC<IPageWrapper> = ({ children, location }) => {
  const isHomePage = location.pathname === INTERNAL_LINKS.HOME;
  const isAffiliatePage = location.pathname?.includes(INTERNAL_LINKS.AFFILIATE);
  const isDealPage = location.pathname?.includes(INTERNAL_LINKS.DEAL);
  const isBlogPage = location.pathname?.includes(INTERNAL_LINKS.BLOG);
  const isBusinessPage =
    location.pathname?.includes(INTERNAL_LINKS.BUSINESS) &&
    !location.pathname?.includes(INTERNAL_LINKS.BUSINESS_BLOG);
  const isDownloadPage = location.pathname?.includes(INTERNAL_LINKS.DOWNLOAD);

  const isDefaultPage =
    !isHomePage &&
    !isAffiliatePage &&
    !isDealPage &&
    !isBusinessPage &&
    !isDownloadPage &&
    !isBlogPage;

  return (
    <>
      <Container>
        {!isDefaultPage && children}
        {isDefaultPage && (
          <PagePaddingWrapper>
            <LightHeader location={location} />
            {children}
            <Footer />
          </PagePaddingWrapper>
        )}
      </Container>
      <GdprPopup />
    </>
  );
};

export default PageWrapper;
