import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { BREAKPOINTS, INTERNAL_LINKS } from 'utils/constants';

const StyledLink = styled(Link)((props) => ({
  color: props.theme.colors.white,
  textDecoration: 'none',
}));

const LinkContainer = styled.div({
  margin: '0 30px',
  [BREAKPOINTS[1350]]: {
    marginTop: 10,
  },
});

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginRight: 30,
  [BREAKPOINTS[1350]]: {
    marginRight: 0,
    marginTop: 30,
    flexDirection: 'column',
  },
});

const InternalLinks: React.FC = () => (
  <Container>
    <LinkContainer>
      <StyledLink to={INTERNAL_LINKS.PRIVACY_POLICY}>Privacy Policy</StyledLink>
    </LinkContainer>
    <LinkContainer>
      <StyledLink to={INTERNAL_LINKS.DELETE_ACCOUNT}>Deleting Account</StyledLink>
    </LinkContainer>
    <LinkContainer>
      <StyledLink to={INTERNAL_LINKS.TERMS_AND_CONDITIONS}>{'Terms & Conditions'}</StyledLink>
    </LinkContainer>
  </Container>
);

export default InternalLinks;
